import { action, decorate, extendObservable } from 'mobx';
import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import CustomerModel from '../models/CustomerModel';
import CustomerAPI from '../services/CustomerAPI';

class CustomerStore {
  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      customer: new CustomerModel(),
      customers: [],
      customersExport: [],
      loading: false,
      optionsCustomersName: [],
      loadingExport: false,
      totalPages: 0,
      size: 10,
      listStatus: [],
      page: 0,
      filters: {},
      sort: 'created,desc',
    });
  }

  reset() {
    this.customer = new CustomerModel();
    this.totalPages = 0;
    this.size = 10;
    this.page = 0;
    this.loading = false;
    this.listStatus = [];
    this.filters = {};
    this.sort = 'created,desc';
  }

  async getCustomerByName(filters) {
    const response = await CustomerAPI.getList({
      size: 1000,
      page: 0,
      sort: 'created,asc',
      ...filters,
    });

    if (!response.error) {
      if (response.content && response.content.length > 0) {
        return response.content;
      }
      return [];
    }

    return [];
  }

  async getList(
    size = 10,
    page = this.page,
    sort = this.sort,
    filters = this.filters
  ) {
    this.loading = true;
    const response = await CustomerAPI.getList({
      size,
      page,
      sort,
      ...filters,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }

    this.customers = response.content.map((usr) => new CustomerModel(usr));
    this.totalPages = response.totalPages;
    this.page = response.number;
    this.loading = false;
  }

  async getExpoxtList(size, page = 0) {
    this.loadingExport = true;
    try {
      const response = await CustomerAPI.getList(
        {
          size: size,
          sort: this.sort,
          search: this.filter,
          page: page,
        },
      );

      if (response.error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
        return { items: [], totalElements: 0, totalPages: 0, currentPage: page };
      }

      return {
        items: response.content.map((usr) => new CustomerModel(usr)),
        totalElements: response.totalElements,
        totalPages: response.totalPages,
        currentPage: page
      };
    } catch (error) {
      console.error('Erro na busca de clientes:', error);
      return { items: [], totalElements: 0, totalPages: 0, currentPage: page };
    } finally {
      this.loadingExport = false;
    }
  }

  async fetchAllItems(type) {
    this.loadingExport = true;
    let allItems = [];
    let currentPage = 0;
    const size = 1000; // Ajuste o tamanho do lote conforme necessário
    let totalPages = 1;

    while (currentPage < totalPages) {
      const { items, totalElements, totalPages: pages, currentPage: page } = await this.getExpoxtList(size, currentPage);

      if (items.length > 0) {
        allItems = [...allItems, ...items];
        totalPages = pages;
        currentPage = page + 1;
      } else {
        // Se não há mais itens, saia do loop
        break;
      }
    }

    this.loadingExport = false;
    return allItems.map((usr) => new CustomerModel(usr));
  }

  async setFilters(filters) {
    this.filters = filters;
    await this.getList(this.size, 0, this.sort, filters);
  }

  async setPage(numPage, size = this.size, isPending = false, sort) {
    this.page = numPage;
    this.size = size;
    sort && (this.sort = sort);
    !isPending ? await this.getList() : await this.getPending();
    return true;
  }

  async delete(data, active) {
    this.loading = true;

    const response = await CustomerAPI.activeClient(
      data.uuid,
      JSON.stringify({
        '@class': 'br.com.stoom.tresCoracoes.model.dto.erp.TresCoracoesERPCustomerDTO',
        active: active,
      })
    );

    this.loading = false;
    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        active === true
          ? 'Usuário Ativado com sucesso'
          : 'Usuário desativado com sucesso'
      );
      return response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getList(this.size, this.page, this.sort);
  }

  /**Cria um novo usuário (cliente) */
  async onCreate() {
    this.loading = true;
    this.customer['email'] = this.customer.user.email;
    this.customer.user.username = this.customer.user.email;

    this.customer['newsletter'] = false;
    const data = JSON.stringify(this.customer);
    const response = await CustomerAPI.createUser(data);

    if (!response.error) {
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Usuário criado com sucesso.'
      );
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    }
    this.loading = false;
    return response;
  }

  /**Atualiza uma propriedade do novo adminstrator*/
  async onChange(prop, value) {
    const customer = this.customer;
    switch (prop) {
      case 'email':
        customer.user.email = value;
        break;
      case 'branchUuid':
        customer.branch.uuid = value.value;
        break;
      case 'exempt':
        value === true
          ? (customer.stateRegistration = 'ISENTO')
          : (customer.stateRegistration = undefined);
        break;
      default:
        customer[prop] = value;
    }
    this.customer = new CustomerModel(customer);
  }

  /**
   * *Busca todas as informações de um usuario customer
   * @param  {} uuid
   */
  async get(uuid) {
    this.loading = true;
    this.customer = new CustomerModel();
    const response = await CustomerAPI.get(uuid);

    if (!response.error) {
      this.customer = new CustomerModel(response);
      this.loading = false;
      return this.customer;
    }
    this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Atualiza informações do cliente */
  async update(listPage, customer, uuidCustomer) {
    this.loading = true;
    let data;
    let uuid;

    if (listPage === true) {
      data = JSON.stringify(customer);
      uuid = uuidCustomer;
    } else {
      data = JSON.stringify(this.customer);
      uuid = this.customer.uuid;
    }
    const response = await CustomerAPI.update(uuid, data);

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }

    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      'Unidade atualizada com sucesso'
    );

    this.loading = false;
    // return response;
  }

  async getListStatus(size = 9999) {
    this.loading = true;
    const response = await CustomerAPI.getList({
      size,
    });

    if (response.error) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
      return [];
    }

    const customers = response.content.map((usr) => new CustomerModel(usr));
    const uniqueStatus = [...new Set(customers.map((item) => item.status))];

    this.listStatus = uniqueStatus.map((e) => ({
      value: Math.random() * 21,
      label: e,
    }));
  }
}

export default CustomerStore;
