import { action, decorate, extendObservable } from 'mobx';

import ToastHelper, { STATUS_HELPER } from '~/helpers/ToastHelper';
import { currencyToNumber, onlyUnique } from '~/helpers/utils/Functions';
import CategoryModel from '~/models/CategoryModel';
import ProductModel from '~/models/ProductModel';
import PriceModel from '~/models/PriceModel';
import FileModel from '~/models/FileModel';
import { ProductTypeEnum } from '~/helpers/utils/enums/ProductTypeEnum';
import SupplierModel from '~/models/SupplierModel';
import CategoryAPI from '../services/CategoryAPI';
import ProductAPI from '../services/ProductAPI';
import UploadAPI from '../services/UploadAPI';
import UnitMeasurementModel from '../models/UnitMeasurementModel';
import { generateTreeUuid } from '../helpers/utils/Functions';
import StockModel from '../models/StockModel';
import { v4 as uuidv4 } from 'uuid';

/**Valores inicias de variaveis observadas */
const initValues = {
  loading: true,
  product: new ProductModel(),
  products: [],
  properties: [],
  classes: [],
  pricings: [],
  getFiles: [],
  valuePropertie: undefined,
  applications: [],
  validateTypeProperties: undefined,
  suppliers: undefined,
  tabType: ProductTypeEnum.MODEL,
  prodOrigens: [],
  productFileToAdd: null,
  listDeleteUnit: [],
  productFileToDelete: null,
  productImageFiles: [],
  productImageFilesDelete: [],
  productImageFilesUrl: [],
  productImageFilesUrlDelete: [],
  listProperties: [],
  unitOfMeasurementList: [],
  loadingExport: false,
  priceDeleteList: [],
  prices: [],
  pricesCopy: [],
  units: [],
  stocksList: [],
  oldStateProduct: new ProductModel(),
  lockProduct: undefined,
  loadingToogle: false,
};
class ProductStore {
  totalPages = 0;
  page = 0;
  size = 10;
  sort = 'created,desc';
  filter = '';
  categoriesRemove = [];
  categoriesAdd = [];
  variations = [];
  princingsRemove = [];

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.toastHelper = new ToastHelper();
    extendObservable(this, {
      ...initValues,
    });
  }

  reset() {
    this.product = new ProductModel();
    this.totalPages = 0;
    this.page = 0;
    this.products = [];
    this.prices = [];
    this.size = 10;
    this.sort = 'created,desc';
    this.filter = '';
    this.categoriesRemove = [];
    this.categoriesAdd = [];
    this.variations = [];
    this.units = [];
    this.stocksList = [];
    this.princingsRemove = [];
    this.applications = [];
    this.productFileToAdd = null;
    this.productFileToDelete = null;
    this.priceDeleteList = [];
    this.productImageFiles = [];
    this.productImageFilesDelete = [];
    this.productImageFilesUrl = [];
    this.productImageFilesDeleteUrl = [];
    this.unitOfMeasurementList = [];
    this.tabType = ProductTypeEnum.MODEL;
    this.validateTypeProperties = undefined;
  }

  resetListing() {
    this.page = 0;
  }

  /**Métodos referente a versão com variação */

  /**Finalizou os métodos referente a versão com variação */

  /**Retorna instancia da notificationStore  */
  get notificationStore() {
    return this.rootStore.notificationStore;
  }

  /**Retorna instancia da propertiesStore */
  get propertiesStore() {
    // return this.rootStore.propertiesStore;
  }

  getCustomerType(customerType) {
    return this.rootStore.customerTypeStore.getCustomerType(customerType);
  }

  /**Cria novo produto */
  createEmptyProduct(isParent) {
    this.product = undefined;
    this.product = new ProductModel();
    this.product.active = false;
    this.product.parent = isParent;
  }

  /**Atualiza propriedades do produto. */
  async onChange(prop, value) {
    const product = this.product;
    switch (prop) {
      case 'name':
        product.name = value;
        if (!product.shortName) product.shortName = value;
        break;
      case 'baseProduct':
        product.baseProduct = { uuid: value?.value };
        break;
      case 'color':
        product.color = { name: value?.label, uuid: value?.value };
        break;
      case 'size':
        product.size = { code: value?.label, uuid: value?.value };
        break;
      case 'voltage':
        product.voltage = { code: value?.label, uuid: value?.value };
        break;
      case 'material':
        product.material = { code: value?.label, uuid: value?.value };
        break;
      case 'description':
        product.description = value;
        if (!product.shortDescription || product.shortDescription.length < 20)
          product.shortDescription = value;
        break;

      case 'supplier':
        product.setSupplier({
          uuid: value?.value,
        });
        break;

      // case 'absoluteDiscount':
      // case 'percentualDiscount':
      // case 'weight':
      // case 'height':
      // case 'width':
      // case 'length':
      // case 'netWeight':
      // case 'shippingWeight':
      // case 'shippingHeight':
      // case 'shippingWidth':
      // case 'shippingLength':

      // break;
      default:
        product[prop] = value;
    }

    this.product = new ProductModel(product);
  }

  /**Manipula lista de categorias selecionadas ou descelecionadas */
  setCategories(selecteds) {
    this.categoriesRemove = this.product.categories.filter(
      (cat) => !selecteds.some((mCat) => mCat.uuid === cat.uuid)
    );
    this.categoriesAdd = selecteds.filter(
      (cat) => !this.product.categories.some((mCat) => mCat.uuid === cat.uuid)
    );
    const output = [...this.product.categories, ...this.categoriesAdd]
      .filter((f) => this.categoriesRemove.indexOf(f) < 0)
      .filter(onlyUnique);

    this.defaultCategoryProduct = generateTreeUuid(selecteds);

    return output;
  }

  addSuppl(data) {
    this.product.supplier = new SupplierModel({
      uuid: data.value,
    });
  }

  removeAppl(uuid) {
    this.product.applications = this.product.applications.filter(
      (m) => m.uuid !== uuid
    );
    this.applications = this.changeToApplicationsSelect(
      this.product.applications
    );
  }

  /**Adiciona imagem ao produto */
  handleProductImages(file, ordering) {
    this.loading = true;
    const newFileItem = new FileModel({
      file,
      ...file,
      metaTags: ['card'],
      size: 1,
      ordering: ordering,
      path: file?.preview,
      uuid: Math.random() * 5,
    });

    this.product.files.push(newFileItem);
    this.productImageFiles.push(newFileItem);
    this.loading = false;
    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      'Imagem cadastrada com sucesso!'
    );
  }

  handleRemoveImageVariation(index) {
    const product = this.product;
    const files = [];

    product.files.map((f, i) => {
      if (index !== i) {
        files.push(f);
      }
    });

    product.files = files.map((f, i) => ({
      ...f,
      metaTags: i === 0 ? ['card'] : [`carousel-${i}`],
    }));

    this.product = new ProductModel(product);
  }

  async addPricingForProduct(propertyGroup) {
    this.loading = true;
    const response = await ProductAPI.addProcuctPricing(
      this.product.uuid,
      propertyGroup
    );
    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getParentProducts(type, name) {
    const search = `${type};${name}`;
    const response = await ProductAPI.list({ search }, ProductTypeEnum.MODEL);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response.content;
  }

  async getAllproducts(name) {
    const search = `name;${name}`;
    const response = await ProductAPI.list({ search }, 'ALL');

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response.content;
  }

  async getProductsVariations(name) {
    const search = `name;*${name}*`;
    const response = await ProductAPI.list(
      { search },
      ProductTypeEnum.VARIATION
    );

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);

    return response.content;
  }

  /**Vincula princig no produto */
  async removePricingForProduct(propertyGroup) {
    this.loading = true;
    const response = await ProductAPI.removeProcuctPricing(
      this.product.uuid,
      propertyGroup.uuid
    );
    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Quando selecionar uma nova página no comp, busca infos relacionadas a ela. */
  async setPage(numPage, size = 10, sort = 'created,desc') {
    this.page = numPage;
    this.size = size;
    this.sort = sort;
    this.tabsPage = this.tabType ? this.tabType : ProductTypeEnum.MODEL;

    await this.getListByType(this.tabType);
  }

  async setSort(sort) {
    this.sort = sort;
    await this.getListByType(this.tabType);
  }

  /**Salva um novo produto */
  async onCreate() {
    this.loading = true;

    if (this.product.part) {
      this.product.itemsCombo = [];
    }
    this.product.cubage = parseFloat(this.product?.cubage);

    if (this.units.length > 0) {
      const newUnitOfMeasurementList = [];
      this.units.filter((item) => {
        newUnitOfMeasurementList.push({
          '@class':
            'br.com.stoom.tresCoracoes.model.TresCoracoesUnitOfMeasurement',
          active: item.active,
          baseQty: item.baseQty,
          name: item.name,
          valEnd: item.valEnd,
          valStart: item.valStart,
        });
      });
      this.product.unitOfMeasurementList = newUnitOfMeasurementList;
    }

    if (this.prices.length > 0) {
      let filterPrices = this.prices.filter(
        (item) => !(item.registerScreen === true && item.deleted === true)
      );
      this.product.prices = filterPrices;
    }

    const data = this.product;
    delete data.file;
    delete data.files;
    data.merchant = this.rootStore.usersStore.userMerchant;

    let response = await ProductAPI.save(data);

    if (!response.error) {
      const product = new ProductModel(response.data);
      this.product = product;
      if (product.baseProduct != null) {
        await this.setProductBaseCategoriesToVariation();
      } else {
        await this.spreadProductBaseCategoriesToProductVariations();
      }

      // Depois que o produto foi cadastrado, as imagens sao upadas na API
      this.sendFiles(product.uuid);
      this.sendImagesUrl(product.uuid);
      this.sendImages(product.uuid);
      this.createEmptyProduct();
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Produto Cadastrado');
      this.loading = false;
    } else {
      if (response?.error) {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          response?.error ?? 'Não foi possível cadastrar o produto'
        );
      }
      this.loading = false;
    }
    this.loading = false;
    return response;
  }

  /**Envia propriedades */
  async handlePricings(productUuid) {
    const groups = [];
    this.propertiesStore.classesSelecteds.map((cls) =>
      groups.push(...cls.groups)
    );

    //Pega todos os princings ativos ( > 0)
    const arrayAdd = [];
    let arrayRemoveds = [];

    //Se grupos forem  > 0 verifica o que é novo e o que foi removido.
    if (groups.length > 0) {
      groups.map((pricing) => {
        if (pricing.priceIncrease > 0) arrayAdd.push(pricing);
        else if (pricing.uuid && pricing.priceIncrease <= 0)
          arrayRemoveds.push(pricing);
        return pricing;
      });
      //Verifica variações que foram removidas.
      arrayRemoveds = [
        ...arrayRemoveds,
        ...this.variations.filter(
          (variation) => !groups.some((group) => group.uuid === variation.uuid)
        ),
      ];
    }
    //Senão todas as variações foram deletadas.
    else arrayRemoveds = this.variations;
    let promises = [];
    promises.push(
      arrayAdd.map(
        async (property) =>
          await ProductAPI.addProcuctPricing(productUuid, property)
      )
    );
    promises.push(
      arrayRemoveds.map(
        async (pricing) =>
          await ProductAPI.removeProcuctPricing(productUuid, pricing.uuid)
      )
    );

    promises.length > 0 &&
      (await this.handlePromises(
        promises,
        'Falha ao desvincular categorias 1'
      ));
  }

  /**Vincula produtos Variacao e produtoBase em categorias. */
  async spreadProductBaseCategoriesToProductVariations() {
    let promises = [];

    let relatedProducts;

    relatedProducts = [this.product, ...this.product.productVariations];

    if (this.categoriesAdd.length > 0) {
      promises = this.categoriesAdd.map(
        async (category) =>
          await CategoryAPI.addProductsCategory(
            category.uuid,
            relatedProducts.map((item) => {
              return { uuid: item.uuid };
            })
          )
      );
    }
    if (this.categoriesRemove.length > 0) {
      promises = [
        ...promises,
        this.categoriesRemove.map(
          async (category) =>
            await CategoryAPI.removeProductsCategory(
              category.uuid,

              relatedProducts.map((item) => {
                return { productUuid: item.uuid };
              })
            )
        ),
      ];
    }
    promises.length > 0 &&
      (await this.handlePromises(
        promises,
        'Falha ao desvincular categorias 2'
      ));
  }

  /**Vincula cateogrias de produto base para o produto variacao. */
  async setProductBaseCategoriesToVariation() {
    let promises = [];

    const relatedProducts = [this.product];

    const categoriesToRemove = await ProductAPI.getCategories(
      this.product.uuid
    );

    if (categoriesToRemove.length > 0) {
      promises = [
        ...promises,
        categoriesToRemove.map(
          async (category) =>
            await CategoryAPI.removeProductsCategory(
              category.uuid,

              relatedProducts.map((item) => {
                return { productUuid: item.uuid };
              })
            )
        ),
      ];
    }

    promises.length > 0 &&
      (await this.handlePromises(
        promises,
        'Falha ao desvincular categorias 3'
      ));

    const categoriesToAdd = await ProductAPI.getCategories(
      this.product.baseProduct.uuid
    );

    if (categoriesToAdd.length > 0) {
      promises = [];

      for (let index = 0; index < categoriesToAdd.length; index++) {
        const category = categoriesToAdd[index];

        const output = await CategoryAPI.addProductsCategory(
          category.uuid,
          relatedProducts.map((item) => {
            return { uuid: item.uuid };
          })
        );

        promises.push(output);
      }

      promises.length > 0 &&
        (await this.handlePromises(promises, 'Falha ao vincular categorias 3'));
    }
  }

  async addImage(data) {
    this.loading = true;

    const newFileItem = new FileModel({
      metaTags: ['card'],
      size: 1,
      ordering: data.ordering,
      path: data?.path,
      uuid: Math.random() * 5,
    });

    this.product.files.push(newFileItem);
    this.productImageFilesUrl.push(newFileItem);

    this.loading = false;
    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      'Imagem cadastrada com sucesso!'
    );
  }

  async removeImage(data) {
    const removeItem = this.product.files.filter(
      (item) => item.uuid !== data.uuid
    );

    this.product.files = removeItem;
    if (data.uuid) {
      this.productImageFilesUrlDelete.push(data);
    }

    if (this.product.files.length === 0) this.product.active = false;
    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      'Imagem removida com sucesso!'
    );
  }

  async addFile(data) {
    this.loading = true;

    const newFileItem = new FileModel({
      ...data,
      metaTags: ['pdf'],
      file: data,
      path: URL.createObjectURL(data),
    });

    // Armazena arquivo antigo
    const oldFile = this.product.file;
    if (oldFile?.uuid) this.productFilesDelete = oldFile;

    // Atribui o novo arquivo para o product
    this.product.files.push(newFileItem);
    //Adiciona o arquivo para a listagem de arquivos a serem adicionados na API
    this.productFileToAdd = newFileItem;
    this.loading = false;

    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      'Manual adicionado com sucesso!'
    );
  }

  /**Busca todos os produtos */
  async getList(data = {}) {
    this.loading = true;

    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      ...data,
    };

    const response = await ProductAPI.list(params);
    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new ProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  async getExpoxtList(size, type, page = 0) {
    this.loadingExport = true;
    try {
      const response = await ProductAPI.list(
        {
          size: size,
          sort: this.sort,
          search: this.filter,
          page: page, // Adiciona o parâmetro de página
        },
        type
      );

      if (response.error) {
        this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
        return { items: [], totalElements: 0, totalPages: 0, currentPage: page };
      }

      return {
        items: response.content.map((usr) => new ProductModel(usr)),
        totalElements: response.totalElements,
        totalPages: response.totalPages,
        currentPage: page
      };
    } catch (error) {
      console.error('Falha ao buscar:', error);
      return { items: [], totalElements: 0, totalPages: 0, currentPage: page };
    } finally {
      this.loadingExport = false;
    }
  }


  async fetchAllItems(type) {
    this.loadingExport = true;
    let allItems = [];
    let currentPage = 0;
    const size = 1000; // Ajuste o tamanho do lote conforme necessário
    let totalPages = 1;

    while (currentPage < totalPages) {
      const { items, totalElements, totalPages: pages, currentPage: page } = await this.getExpoxtList(size, type, currentPage);

      if (items.length > 0) {
        allItems = [...allItems, ...items];
        totalPages = pages;
        currentPage = page + 1;
      } else {
        // Se não há mais itens, saia do loop
        break;
      }
    }

    this.loadingExport = false;
    return allItems.map((usr) => new ProductModel(usr));
  }

  /**Busca todos os produtos pelo tipo*/
  async getListByType(productType) {
    this.loading = true;
    const params = {
      page: this.page,
      size: this.size,
      sort: this.sort,
      search: this.filter,
    };

    const response = await ProductAPI.list(params, productType);
    if (!response.error) {
      this.tabType = productType;

      this.products = await response.content.map(
        (prd) => new ProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
      this.loading = false;
      return response;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  /**Busca todos os produtos pelo tipo*/
  // async exportCSVByType(productType) {
  //   this.loading = true;
  //   const params = {
  //     sort: this.sort,
  //     search: this.filter,
  //   };

  //   const response = await ProductAPI.exportCSVByType(params, productType);
  //   if (!response.error) {
  //     this.loading = false;
  //     return response;
  //   } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
  //   this.loading = false;
  // }

  async getListBySku(skuCode = this.filter) {
    this.loading = true;
    const params = {
      sku: skuCode,
    };
    const response = await ProductAPI.getProductBySku(params);
    if (!response.error) {
      this.products = await response.content.map(
        (prd) => new ProductModel(prd)
      );
      this.totalPages = response.totalPages;
      this.page = response.number;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
  }

  // Adicionar a propriedade
  async addPropertieSelected(propertie) {
    this.loading = true;
    const merchant = this.rootStore.usersStore.userMerchant;

    const propertyClass = propertie.propertyClass;
    const propertyToPush = {
      ...propertie,
      ...{ propertyClass: { ...propertyClass, merchant } },
    };
    this.product.productProperties.push(propertyToPush);
    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      'Especificação cadastrada com sucesso!'
    );
    this.loading = false;
  }

  /**Atualiza Produto */
  async onUpdate(removeItem) {
    // this.oldStateProduct = this.product
    delete this.product.merchant;
    delete this.product.file;

    if (this.product.part) {
      this.product.itemsCombo = [];
    }
    const files = this.product.files;

    await this.sendFiles(this.product.uuid);
    await this.sendImagesUrl(this.product.uuid);
    await this.sendImages(this.product.uuid);

    if (removeItem) {
      this.product.baseProduct = null;
    }

    if (this.units.length > 0) {
      const newUnitOfMeasurementList = [];
      this.units.filter((item) => {
        if (String(item.uuid).length < 36) {
          newUnitOfMeasurementList.push({
            '@class':
              'br.com.stoom.tresCoracoes.model.TresCoracoesUnitOfMeasurement',
            active: item.active,
            baseQty: item.baseQty,
            name: item.name,
            valEnd: item.valEnd,
            valStart: item.valStart,
            displayUnits: item.displayUnits,
          });
        } else {
          newUnitOfMeasurementList.push({
            '@class':
              'br.com.stoom.tresCoracoes.model.TresCoracoesUnitOfMeasurement',
            active: item.active,
            baseQty: item.baseQty,
            created: item.created,
            deleted: item.deleted,
            externalId: item.externalId,
            modified: item.modified,
            name: item.name,
            uuid: item.uuid,
            displayUnits: item.displayUnits,
            valEnd: item.valEnd,
            valStart: item.valStart,
          });
        }
      });
      this.product.unitOfMeasurementList = newUnitOfMeasurementList;
    }

    if (this.prices.length > 0) {
      const filterPrices = this.prices.filter(
        (item) => !(item.registerScreen === true && item.deleted === true)
      );

      this.product.prices = filterPrices;
    }

    if (this.product.files.length === 0) this.product.active = false;
    if (this.stocksList.length > 0) {
      this.product.stocks = this.stocksList;
    }

    const data = JSON.stringify(this.product);

    const response = await ProductAPI.update(this.product.uuid, data);
    if (!response.error) {
      const product = new ProductModel(response.data);
      this.product = this.product;
      if (product.baseProduct != null) {
        await this.setProductBaseCategoriesToVariation();
      } else {
        await this.spreadProductBaseCategoriesToProductVariations();
      }
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Alterações gravadas com sucesso.'
      );
      this.productImageFiles = [];
      this.productImageFilesDelete = [];
      this.productImageFilesUrl = [];
      this.productImageFilesUrlDelete = [];
    } else {
      this.product.files = files;
      this.productImageFiles = [];
      this.productImageFilesDelete = [];
      this.productImageFilesUrl = [];
      this.productImageFilesUrlDelete = [];
      this.product = this.product;
      // this.product.categories = categories;

      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        response?.error ?? 'Não foi possível cadastrar o produto'
      );
    }
    this.loading = false;
    return response;
  }

  /**Deleta produto */
  async onDelete(uuid) {
    this.loading = true;
    const response = await ProductAPI.delete(uuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Produto excluído');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);
    this.loading = false;
  }

  /**Desassocia a variação */
  async disableVariation(variationUuid) {
    const response = await ProductAPI.disableVariation(variationUuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Variação desabilitada');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  /**Desassocia a variação */
  async activateVariation(variationUuid) {
    const response = await ProductAPI.activateVariation(variationUuid);
    if (!response.error) {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Variação habilitada');
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error, 4000);

    return response;
  }

  /**Busca categorias do produto */
  async getCategories(productUuid) {
    const response = await ProductAPI.getCategories(productUuid);
    let categories = [];
    if (!response.error) {
      categories = response.map((cat) => new CategoryModel(cat));
      this.defaultCategoryProduct = generateTreeUuid(categories);
    } else this.toastHelper.notify(STATUS_HELPER.error, response.error);
    return categories;
  }

  /**Busca apenas um determinado produto */
  async get(value, prop = 'uuid') {
    this.loading = true;
    const response = await ProductAPI.get(prop, value);
    if (!response.error) {
      const product = new ProductModel(response);
      const priceTemp = [];

      product.prices.map((item) => {
        const unitOfMeasurementList = product.unitOfMeasurementList.find(
          (i) =>
            item.initialAmount >= i.valStart &&
            ((item.finalAmount > 0 && item.finalAmount <= i.valEnd) ||
              i.valEnd == 0)
        );
        if (unitOfMeasurementList !== undefined) {
          item.unitMeasurementLabel =
            unitOfMeasurementList.name + ' - ' + unitOfMeasurementList.baseQty;
          item.unitMeasurementUuid = unitOfMeasurementList.uuid;
        } else {
          item.unitMeasurementLabel = 'Unidade não cadastrada';
        }
        item.customerType = {
          name: 'J',
          uuid: '0a196453-d596-41a3-839b-46b614601acf2',
        };
        item.minimumPrice =
          item.minimumPrice != undefined ? item.minimumPrice : 0;
        priceTemp.push(item);
      });

      this.units = product.unitOfMeasurementList
        ? product.unitOfMeasurementList.map(
          (prop) => new UnitMeasurementModel(prop)
        )
        : [];

      const estoque =
      {
        skuProduct: response.skuCode,
      }



      this.stocksList = await ProductAPI.getStock(estoque);

      // this.stocksList = product.stocks
      //   ? product.stocks.map(
      //     (prop) => new StockModel(prop)
      //   )
      //   : [];

      const filterPriceShow = priceTemp.filter((item) => item.show === true);


      this.prices = priceTemp;
      this.pricesCopy = priceTemp;
      this.getFiles = product.files;

      if (product.parent)
        product.categories = await this.getCategories(product.uuid);

      this.product = new ProductModel(product);
      this.loading = false;
    } else this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    this.loading = false;
    return response;
  }

  /**Busca apenas um determinado produto */
  async getViewPdp(value, prop = 'uuid') {
    this.loading = true;
    const response = await ProductAPI.get(prop, value);
    if (!response.error) {
      const product = new ProductModel(response);

      if (product.parent)
        product.categories = await this.getCategories(product.uuid);

      this.product = new ProductModel(product);
      this.loading = false;
    } else {
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
      this.loading = false;
    }
    return response;
  }

  /**Envia arquivo de proposta que foi anexo */
  async sendFiles(productUuid) {
    this.productFileToAdd &&
      (await this.sendNewFiles([this.productFileToAdd], productUuid, 'manual'));
    this.productFileToDelete &&
      (await this.deleteFiles([this.productFileToDelete]));
  }

  /**Envia arquivo de proposta que foi anexo */
  async sendImagesUrl(productUuid) {
    this.productImageFilesUrl.length > 0 &&
      (await this.sendNewFiles(this.productImageFilesUrl, productUuid, 'url'));
    this.productImageFilesUrlDelete.length > 0 &&
      (await this.deleteFiles(this.productImageFilesUrlDelete));
  }

  /**Envia arquivo de proposta que foi anexo */
  async sendImages(productUuid) {
    this.productImageFiles.length > 0 &&
      (await this.sendNewFiles(this.productImageFiles, productUuid, 'image'));
    this.productImageFilesDelete.length > 0 &&
      (await this.deleteFiles(this.productImageFilesDelete));
  }

  /**Funcão útil paradá feedbeack sobre envio de arquivos. */
  async handlePromises(promises, message) {
    const response = await Promise.all(promises);
    const errors = response.filter((r) => r && r.error !== undefined);
    if (errors && errors.length > 0) {
      this.toastHelper.notify(STATUS_HELPER.ERROR, message);
    }
    if (response.error)
      this.toastHelper.notify(STATUS_HELPER.ERROR, response.error);
    return response;
  }

  /**Atualiza imagem da categoria */
  async deleteFiles(files) {
    const promise = files.map(async (file) => {
      await UploadAPI.removeFileProduct(file.uuid);
    });
    return await this.handlePromises(promise, 'Falha o excluir arquivo');
  }

  async updateFiles(updatedFiles) {
    const promiseUpdate = updatedFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      await UploadAPI.updateFile(file);
    });
    const response = await this.handlePromises(
      promiseUpdate,
      'Falha atualizar arquivos.'
    );
    setTimeout(() => {
      updatedFiles.forEach((file) =>
        this.notificationStore.removeItemUpload(file)
      );
    }, 3300);
    return response;
  }

  async sendNewFiles(newFiles, productUuid, type) {
    const promiseNew = newFiles.map(async (file) => {
      this.notificationStore.addItemUpload(file);
      if (type === 'manual') {
        await UploadAPI.uploadProductByImageFile(productUuid, file);
      } else if (type === 'image') {
        if (file.uuid.length === 36) {
          await UploadAPI.uploadProduct(productUuid, file);
        } else {
          file.uuid = undefined;
          await UploadAPI.uploadProduct(productUuid, file);
        }
      } else if (type === 'url') {
        if (file.uuid.length === 36) {
          await UploadAPI.uploadProductByImageURL(productUuid, file);
        } else {
          file.uuid = undefined;
          await UploadAPI.uploadProductByImageURL(productUuid, file);
        }
      }
    });
    const response = await this.handlePromises(
      promiseNew,
      'Falha anexar arquivos.'
    );
    setTimeout(() => {
      newFiles.forEach((file) => this.notificationStore.removeItemUpload(file));
    }, 3300);
    return response;
  }

  /**Retorna lista de categorias para uso no select */
  getListSelect(products = this.products) {
    return products.map((prd) => ({
      value: prd.uuid,
      label: `${prd.skuCode} - ${prd.name}`,
    }));
  }

  changeToApplicationsSelect(app) {
    return app.map((m) => ({
      value: m.uuid,
      label: m.getNameSelect,
    }));
  }

  async checkCSV(newFiles) {
    this.loading = true;
    let promiseNew = [await this.notificationStore.addItemUpload(newFiles)];
    promiseNew = [...promiseNew, await UploadAPI.checkCSV(newFiles)];
    const response = await this.handlePromises(
      promiseNew,
      'Falha anexar arquivos.'
    );
    setTimeout(
      () => (newFiles) => this.notificationStore.removeItemUpload(newFiles),
      3300
    );
    this.loading = false;
    return response[1];
  }

  async sendCSV(newFiles) {
    this.loading = true;
    let promiseNew = [await this.notificationStore.addItemUpload(newFiles)];
    promiseNew = [...promiseNew, await UploadAPI.sendCSV(newFiles)];
    const response = await this.handlePromises(
      promiseNew,
      'Falha anexar arquivos.'
    );
    if (response[1].data.sucess) {
      this.toastHelper.notify(STATUS_HELPER.SUCCESS, 'Atualizado com sucesso');
    }
    setTimeout(
      () => (newFiles) => this.notificationStore.removeItemUpload(newFiles),
      3300
    );
    this.loading = false;
    return response[1];
  }

  async getCsvProducts() {
    this.loading = true;
    let response = await UploadAPI.getProductsCSV();
    if (!response.error) {
      this.loading = false;
      return response.data;
    }
    this.loading = false;
    return;
  }

  /**Adiciona Parte/Produto no itemsCombo */
  addComboSelected(partProduct) {
    this.product.itemsCombo.push(partProduct);
  }

  filterListPrice(filter) {
    const dadosFiltrados = this.prices.filter((item) =>
      JSON.stringify(item).includes(filter)
    );

    this.prices = dadosFiltrados;
  }

  async deleteManual(files) {
    if (files.uuid) {
      await UploadAPI.removeFileProduct(files.uuid);
    }
    this.product.files = this.product.files.filter(
      (e) => e.metaTags[0] !== 'pdf'
    );
    this.get(this.product.uuid);

    this.toastHelper.notify(STATUS_HELPER.INFO, 'Removido com sucesso!');
  }

  setSortPriceList(e) {
    const field = e.split(',')[0];
    const direction = e.split(',')[1];

    switch (field) {
      case 'center':
      case 'tableId':
      case 'unitMeasurementLabel':
        this.prices = this.sortByFieldAndStringDirection(
          field,
          direction || 'asc'
        );
        break;
      case 'minimumPrice':
      case 'price':
      case 'show':
      case 'initialAmount':
      case 'finalAmount':
        this.prices = this.sortByFieldAndDirection(field, direction);
        break;
      default:
        // Lógica para outro campo, se necessário
        break;
    }
  }

  sortByFieldAndDirection(field, direction) {
    return this.prices.sort((a, b) => {
      if (direction === 'desc') {
        return b[field] - a[field];
      } else {
        return a[field] - b[field];
      }
    });
  }

  sortByFieldAndStringDirection(field, direction) {
    return this.prices.sort((a, b) => {
      if (direction === 'desc') {
        return b[field].localeCompare(a[field]);
      } else {
        return a[field].localeCompare(b[field]);
      }
    });
  }

  clearFilter() {
    this.prices = this.pricesCopy;
  }

  /**Remove a Parte/Produto no itemsCombo */
  removeComboSelected(element) {
    this.loading = true;
    const itemsCombo = this.product.itemsCombo.filter(
      (e) => e.product.uuid !== element.product.uuid
    );
    this.product.itemsCombo = itemsCombo;
    this.loading = false;
  }

  //**Valida se o produto possui categoria ativa no cadastro */
  checkProductHasCategory() {
    if (this.product.categories && this.product.categories.length > 0) {
      if (this.categoriesRemove.length === this.product.categories.length) {
        if (this.categoriesAdd.length > 0) {
          return true;
        } else {
          return false;
        }
      } else return true;
    } else if (this.categoriesAdd.length > 0) {
      return true;
    }

    return false;
  }

  async getProdOrigens() {
    const response = await ProductAPI.getPordOrigens();

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
  }

  async removePropertieSelected(data) {
    this.loading = true;
    const filterItens = this.product.productProperties.filter(
      (item) => item.uuid !== data
    );
    this.product.productProperties = filterItens;
    this.loading = false;
    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      'Especificação removido com sucesso!'
    );
  }

  /**Retorna variações filtradas de acordo com o term */
  async getAvailableProductVariations(filter) {
    const response = await ProductAPI.getAvailableProductVariations(filter);

    if (response.error)
      this.notificationStore.notify(STATUS_HELPER.ERROR, response.error);
    else return response;
  }

  addProductVariation(product) {
    this.product.productVariations.push(product);
    this.validateTypeProperties = undefined;
  }

  removeProductVariation(product) {
    this.product.productVariations = this.product.productVariations.filter(
      (variation) => variation.uuid !== product.uuid
    );
  }

  addProductPrice(data) {
    this.loading = true;
    const unit = this.units.find((i) => i.uuid === data.unitMeasurement.value);

    if (
      this.prices.find(
        (item) =>
          item?.center === data?.center &&
          item?.tableId === data?.tableId &&
          parseFloat(item?.minimumPrice) === parseFloat(data.minimumPrice) &&
          parseFloat(item?.price) === parseFloat(data.price) &&
          item.deleted === false
      )
    ) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Já existe preço cadastrado com esses valores'
      );

      this.loading = false;
    } else {
      const unitValue =
        unit.valEnd.toString() === '0' ? '9999999' : unit.valEnd;

      if (parseInt(unit.valStart) > parseInt(data.initialAmount)) {
        this.toastHelper.notify(
          STATUS_HELPER.ERROR,
          'não é possível cadastrar uma quantidade inferior ao valor base'
        );
      } else {
        if (parseInt(data.initialAmount) > parseInt(unitValue)) {
          this.toastHelper.notify(
            STATUS_HELPER.ERROR,
            'não é possível cadastrar uma quantidade superior ao valor da unidade base'
          );
        } else {
          if (
            this.prices.find(
              (item) =>
                item?.center === data?.center &&
                item?.tableId === data?.tableId &&
                item.initialAmount.toString() ===
                data.initialAmount.toString() &&
                item.deleted === false
            )
          ) {
            this.toastHelper.notify(
              STATUS_HELPER.ERROR,
              'não é possível cadastrar uma quantidade igual para o centro e tabela selecionados'
            );

            this.loading = false;
            return;
          }

          //Faz validação se a unidade é igual a tabela de preço caso seja ele deve alterar todos os demais.
          if (
            this.prices.find(
              (item) =>
                item?.center === data?.center &&
                item?.tableId === data?.tableId &&
                item.deleted === false
            )
          ) {
            this.prices.push({
              '@class': 'br.com.stoom.tresCoracoes.model.TresCoracoesPrice',
              uuid: Math.random() * 21,
              price: currencyToNumber(data.price),
              minimumPrice: currencyToNumber(data.minimumPrice),
              unitMeasurementLabel: unit.name + ' - ' + unit.baseQty,
              unitMeasurementUuid: unit.uuid,
              customerType: {
                name: 'J',
                uuid: '0a196453-d596-41a3-839b-46b614601acf2',
              },
              initialAmount: data.initialAmount,
              finalAmount: unit.valEnd,
              center: data.center,
              tableId: data.tableId.toUpperCase(),
              nameMeasurement: unit.name,
              deleted: false,
              show: true,
              registerScreen: true,
            });

            const filteredItems = this.prices.filter((item) => {
              return (
                item.center === data.center && item.tableId === data.tableId
              );
            });

            const sortItem = filteredItems.sort((a, b) => {
              const initialAmountA = parseInt(a.initialAmount);
              const initialAmountB = parseInt(b.initialAmount);

              if (initialAmountA < initialAmountB) {
                return -1;
              }
              if (initialAmountA > initialAmountB) {
                return 1;
              }
              return 0;
            });

            function atualizarFinalAmount(prices) {
              for (let i = 0; i < prices.length - 1; i++) {
                const next = prices[i + 1];

                const nextFinalAmount = parseInt(next.initialAmount) - 1;
                prices[i].finalAmount = nextFinalAmount;
              }

              return prices;
            }

            const pricesAtualizados = atualizarFinalAmount(sortItem);
            this.pricesCopy = pricesAtualizados;

            this.toastHelper.notify(
              STATUS_HELPER.SUCCESS,
              'Preço cadastrado com sucesso!'
            );

            this.loading = false;
          } else {
            this.prices.push({
              '@class': 'br.com.stoom.tresCoracoes.model.TresCoracoesPrice',
              uuid: Math.random() * 21,
              price: currencyToNumber(data.price),
              minimumPrice: currencyToNumber(data.minimumPrice),
              unitMeasurementLabel: unit.name + ' - ' + unit.baseQty,
              unitMeasurementUuid: unit.uuid,
              customerType: {
                name: 'J',
                uuid: '0a196453-d596-41a3-839b-46b614601acf2',
              },
              initialAmount: data.initialAmount,
              finalAmount: unit.valEnd,
              center: data.center,
              show: true,
              tableId: data.tableId,
              nameMeasurement: unit.name,
              deleted: false,
              registerScreen: true,
            });

            this.prices = this.prices.sort(
              (a, b) => a.initialAmount - b.initialAmount
            );

            this.pricesCopy = this.prices;

            this.toastHelper.notify(
              STATUS_HELPER.SUCCESS,
              'Preço cadastrado com sucesso!'
            );
          }
        }
      }
    }

    this.loading = false;
  }

  sortPricesAsc(sort) {
    this.loading = true;

    if (sort === 'customerType') {
      this.prices = this.prices.sort((a, b) => {
        if (a.customerType < b.customerType) {
          return -1;
        }
        if (a.customerType > b.customerType) {
          return 1;
        }
        return 0;
      });

      this.loading = false;
    }
  }

  sortPricesDesc(sort) {
    this.loading = true;
    if (sort === 'customerType,desc') {
      this.prices = this.prices.sort((a, b) => {
        if (a.customerType > b.customerType) {
          return 1;
        }
        if (a.customerType < b.customerType) {
          return -1;
        }
        return 0;
      });

      this.loading = false;
    }
  }

  getUuidUnit(uuid) {
    return this.units.find((i) => (i.uuid = uuid));
  }

  removeProductPrice(price) {
    this.loading = true;
    let listPrices = [];

    const novaListaDePrecos = this.prices.map((preco) => {
      if (preco?.uuid === price?.uuid) {
        return {
          ...preco,
          deleted: true,
        };
      }
      return preco;
    });

    this.prices = novaListaDePrecos;
    this.pricesCopy = listPrices;

    this.toastHelper.notify(
      STATUS_HELPER.SUCCESS,
      'Preço removido com sucesso!'
    );

    const filterPriceShow = novaListaDePrecos.filter(
      (item) => item.show === true
    );

    const allDeleted = filterPriceShow.every((item) => item.deleted === true);

    if (allDeleted) {
      this.loading = true;
      this.product.active = false;
      this.lockProduct = false;

      setTimeout(() => {
        this.loading = false;
      }, 500);
    } else {
      this.lockProduct = true;
    }

    this.loading = false;
  }

  valueProperties(data) {
    this.valuePropertie = data;

    if (data.item.length > 0) {
      this.validateTypeProperties = 'Boolean';
      this.listProperties = data.item.map((item) => {
        return { label: item.displayString, value: item.uuid, item: item };
      });
    } else {
      this.validateTypeProperties = 'Text';
      this.listProperties = [];
    }
  }

  addProductUnitOfMeasurementList(unit) {
    this.loading = true;
    const unitToPush = new UnitMeasurementModel({
      name: unit.nameUnitMeasurementForm.toUpperCase(),
      baseQty: unit.baseQtyUnitMeasurementForm,
      displayUnits: unit.displayUnits,
      uuid: uuidv4(),
      deleted: false,
    });

    const unitList = this.units;

    if (this.units.length === 0) {
      this.units.push(unitToPush);
      this.getUnitOfMeasurementList();
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Unidade cadastrada com sucesso!'
      );
      this.loading = false;
    } else if (
      unitList.filter((i) => i.name === unitToPush.name && i.deleted != true)
        .length === 1
    ) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Já existe uma unidade cadastrada com o mesmo nome'
      );
      this.loading = false;
    } else {
      this.units.push(unitToPush);
      this.getUnitOfMeasurementList();
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Unidade cadastrada com sucesso!'
      );
      this.loading = false;
    }
    this.loading = false;
  }

  removeProductUnitOfMeasurementList(unit) {
    this.loading = true;
    let listUnit = [];

    const existisUnitWithPrice = this.prices.find(
      (i) => i.unitMeasurementUuid === unit.uuid && i.deleted !== true
    );

    if (
      existisUnitWithPrice?.unitMeasurementUuid !== undefined &&
      existisUnitWithPrice.show === true
    ) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Existe preço atrelado para está unidade, não sendo possivel a sua exclusão'
      );
      this.loading = false;
    } else {
      this.units.map((item) => {
        let temp;
        if (unit.uuid.length === 36) {
          if (item.uuid === unit.uuid) {
            item.deleted = true;
            temp = item;
          } else {
            temp = item;
          }
        } else {
          if (String(item.uuid) !== String(unit.uuid)) {
            temp = item;
          }
        }
        temp != undefined && listUnit.push(temp);
      });
      this.units = listUnit;
      this.toastHelper.notify(
        STATUS_HELPER.SUCCESS,
        'Unidade removida com sucesso!'
      );
      this.getUnitOfMeasurementList();
    }
    this.loading = false;
  }

  getUnitOfMeasurementList() {
    const unitList = this.units;
    let listUnitDeletedTrue = unitList.filter((item) => item.deleted === true);

    let invertedArray = unitList
      .sort((a, b) => a.baseQty - b.baseQty)
      .slice(0)
      .reverse();
    let listUnitDeletedFalse = invertedArray.filter(
      (item) => item.deleted === false
    );
    this.units = [];
    let temp = 0;

    listUnitDeletedFalse.map((item, index) => {
      if (index === 0) {
        item.valStart = item.baseQty;
        item.valEnd = 0;
        temp = item.baseQty;
      } else {
        item.valStart = item.baseQty;
        item.valEnd = temp === 0 ? 0 : temp - 1;
        temp = item.baseQty;
      }
    });
    this.units = listUnitDeletedFalse;
    this.units.push(...listUnitDeletedTrue);

    this.unitOfMeasurementList = listUnitDeletedFalse
      .filter((i) => i.disabled != true)
      .map((item) => {
        return { label: `${item.name} - ${item.baseQty}`, value: item.uuid };
      });
  }

  addProductStockList(stock) {
    const stockPush = new StockModel({
      center: stock.center,
      active: true,
      openSale: true,
      total: stock.total,
      unit: stock.unit.value,
      uuid: uuidv4(),
    });

    //Verifica se existe um item com mesma tabela de unidade
    const existingStock = this.stocksList.find(
      (i) => i.center === stockPush.center && i.unit === stockPush.unit
    );

    if (existingStock !== undefined) {
      this.toastHelper.notify(
        STATUS_HELPER.ERROR,
        'Já possui um estoque para o centro e unidade selecionados'
      );

      return;
    }
    this.stocksList.push(stockPush);
  }

  removeProductStockList(stock) {
    const removeList = this.stocksList.filter((item) => item.uuid !== stock.uuid);
    this.stocksList = removeList;
  }

  changeToogleProduct() {
    this.lockProduct = false;
    this.loadingToogle = true;

    const filterPriceShow = this.prices.filter((item) => item.show === true);

    const allDeleted = filterPriceShow.every((item) => item.deleted === true);

    if (allDeleted) {
      this.product.active = false;
      this.lockProduct = false;
    } else {
      this.lockProduct = undefined;
    }

    setTimeout(() => {
      this.loadingToogle = false;
    }, 100);
  }
}

export default ProductStore;
