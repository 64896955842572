import { format, startOfDay, addMinutes } from 'date-fns';
import * as _ from 'lodash';
import { ComponentHomeTypesEnum } from '~/helpers/utils/enums/ComponentHomeTypesEnum';
const moment = require('moment');

/**
 * Método Assincrono que retorna algorítimo SHA-512 que vai criptografar a senha do usuário
 * @returns Retorna uma promise
 * @param  {string} pass password
 */
export async function cryptoPass(pass) {
  const SHA512 = require('crypto-js/sha512');
  const arrayResult = SHA512(pass).words;
  const byteArray = new Uint32Array(arrayResult);
  const hexCodes = [...byteArray].map((value) => {
    const hexCode = value.toString(16);
    const paddedHexCode = hexCode.padStart(2, '0');
    return paddedHexCode;
  });
  return hexCodes.join('');
}

/**
/**Verifica se possui um callback válido e devolve para quem chamou
 * @param  {string} e eventClick
 * @param  {function} callback funcao de retorno
 * @param  {object} data objeto que será devolvido
 */
export function clickGeneric(e, callback, data) {
  if (callback) {
    callback(e, data);
  }
}

/**Return numbers without characteres */
export const onlyNumbers = (value) => (value ? value.replace(/\D/g, '') : '');

//**Used on filter of array to distinct values */
export const onlyUnique = (value, index, self) => self.indexOf(value) === index;

export const currencyToNumber = (value, currency = 'R$') => {
  const string = value.replace('.', '').replace(',', '.').replace(currency, '');
  return Number(string);
};

export const toFloat = (value, toReplace = '') => {
  const string = value
    .replace('.', '')
    .replace(',', '.')
    .replace(toReplace, '');
  return parseFloat(string);
};

export const validateImgLink = (value) => {
  const reg =
    /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?|magnet:\?xt=urn:btih:/;
  if (reg.test(value)) {
    return true;
  }
  return false;
};

/**onChangeValue need to be a function */
export const onChangeValue = (e, prop, obj = this) => {
  obj[prop] = e.target.value;
};

/*
 * Transforma uma data em uma string no formato YYYY-MM-DD
 */
export function dateToText(value, separator = '/', reverse = false) {
  if (!value) return '';
  const date = new Date(value);

  const month = `${'0'}${date.getMonth() + 1}`.slice(-2);
  const day = `${'0'}${date.getDate()}`.slice(-2);

  if (reverse) {
    return `${day}${separator}${month}${separator}${date.getFullYear()}`;
  }

  return `${date.getFullYear()}${separator}${month}${separator}${day}`;
}
/*
 * Dado uma string válida, retorna uma data.
 */
export function textToDate(dateStr) {
  const [day, month, year] = dateStr.split('/');
  const date = new Date(year, month - 1, day);
  return date;
}

/**Retorna data convertida
 * 29 de Agosto de 2019 *
 */
export const convertDate = (dateConvert) => {
  let date;
  if (dateConvert instanceof Date) {
    date = dateConvert;
  } else date = new Date(dateConvert);

  const datePart = dateConvert.match(/\d+/g);
  // requer um dia da semana jutamente com uma data longa
  const options = { year: 'numeric', month: 'long', day: 'numeric' };
  return (
    date.toLocaleDateString('pt-BR', options) +
    ' ás ' +
    datePart[3] +
    ':' +
    datePart[4]
  );
};

/**Converte valor float em string*/
export const convertPrice = (value = 0, currency = 'BRL') =>
  value.toLocaleString('pt-BR', {
    style: 'currency',
    currency,
  });

/**Formata moedas */
export const formatNumbers = (value, prefix = '', suffix = '') => {
  if (!value) {
    return prefix.concat('0,00').concat(suffix);
  }

  if (!Number.isNaN(value)) {
    if (value.parseFloat) {
      value = value.parseFloat(value).toFixed(2);
    } else if (value.toFixed) {
      value = value.toFixed(2);
    }
  }

  let v = value.toString().replace(/\D/g, '');
  v = `${(v / 100).toFixed(2)}`;
  v = v.replace('.', ',');
  v = v.replace(/(\d)(\d{3})(\d{3}),/g, '$1.$2.$3,');
  v = v.replace(/(\d)(\d{3}),/g, '$1.$2,');
  return prefix.concat(v).concat(suffix);
};

export const formatDecimal = (value) => {
  const regex = /^[0-9.]+$/;
  const strippedValue = String(value).replace(/[^\d.-]+/g, '');
  return String(strippedValue).match(regex)?.[0] || '';
};

/**Decode base64 */
export const decodeJWT = (token) => {
  const base64Url = token.split('.')[1];
  return JSON.parse(window.atob(base64Url));
};
/**Rememove  */
export const removeItemList = (list = [], item) => {
  list.splice(list.indexOf(item), 1);
};

/**Sort listBy prop */
export const sortListBy = (list, prop, order) =>
  list.sort((a, b) => {
    a = a[prop];
    b = b[prop];
    const value = order === 'cres' ? -1 : 1;
    if (a < b) {
      return value;
    }
    if (a > b) {
      return value * -1;
    }
    return 0;
  });

export const isArrayEmpty = (array) => {
  if (!array) {
    return true;
  }

  if (array.length > 0) {
    return false;
  }

  return true;
};

export const isObjectEmpty = (obj) => {
  if (!obj) {
    return true;
  }
  if (Object.keys(obj).length === 0 && obj.constructor === Object) {
    return true;
  }
  return false;
};

export const checkIfContainsAllItems = (
  arr,
  target,
  arrPropName,
  targetPropName
) => {
  if (!arr || !target) return false;

  const isValid = target.every(
    (v) => arr.findIndex((f) => f[arrPropName] === v[targetPropName]) > -1
  );

  return isValid;
};

export const onlyUniqueByProp = (value, index, self, propName) =>
  self.findIndex((t) => t[propName] === value[propName]) === index;

export const numberIsEmptyOrZero = (value) => {
  if (!value) {
    return true;
  }

  if (Number.isNaN(value)) {
    return false;
  }

  let valueToCheck;

  if (typeof value === 'string') {
    value = value.replace(',', '.');

    valueToCheck = Number.parseFloat(value);
  } else if (typeof value !== 'number') {
    return false;
  }

  if (valueToCheck === 0) {
    return true;
  }

  return false;
};

export function simpleDateToText(value) {
  if (!value) return '';

  return value.substring(0, 10).split('-').reverse().join('/');
}

export const getDate = (dateValue) => {
  if (!dateValue) return undefined;
  if (dateValue.length < 10) return undefined;

  let separator;
  let reversed;

  if (dateValue.includes('-')) {
    separator = '-';
    reversed = true;
  } else if (dateValue.includes('/')) {
    separator = '/';
    reversed = false;
  }

  const values = dateValue.split(separator);

  let value;

  if (!reversed) {
    //'{mes} {dia} {ano}'
    value = new Date(`${values[1]} ${values[0]} ${values[2]}`);
  } else {
    //'{mes} {dia} {ano}'
    value = new Date(`${values[1]} ${values[2]} ${values[0]}`);
  }

  if (Number.isNaN(value.getDate())) {
    return undefined;
  }

  return value;
};

/* Result 23:59 */
export function getHoursAndMinutes(date) {
  date = new Date(date);
  const hours = (date.getHours() < 10 ? '0' : '') + date.getHours();
  const minutes = (date.getMinutes() < 10 ? '0' : '') + date.getMinutes();
  return `${hours}:${minutes}`;
}

export const toDecimal = (value, decimalPlaces = 2) => {
  const string = value.replace('.', '').replace(',', '.');
  return parseFloat(string).toFixed(decimalPlaces);
};

export const validateDate = (dateValue) => {
  if (!dateValue) return false;
  if (dateValue.length < 10) return false;

  let separator;
  let reversed;

  if (dateValue.includes('-')) {
    separator = '-';
    reversed = true;
  } else if (dateValue.includes('/')) {
    separator = '/';
    reversed = false;
  }

  const values = dateValue.split(separator);

  let value;

  if (!reversed) {
    //'{mes} {dia} {ano}'
    value = new Date(`${values[1]} ${values[0]} ${values[2]}`);
  } else {
    //'{mes} {dia} {ano}'
    value = new Date(`${values[1]} ${values[2].substring(0, 2)} ${values[0]}`);
  }

  if (Number.isNaN(value.getDate())) {
    return false;
  }

  return true;
};

export const clearCPFAndCNPJMask = (value) => {
  if (value) {
    return value.replace(/\D/g, '');
  }

  return value;
};

export const formatCNPJ = (value) => {
  return value
    .replace(/\D+/g, '') // não deixa ser digitado nenhuma letra
    .replace(/(\d{2})(\d)/, '$1.$2') // captura 2 grupos de número o primeiro com 2 digitos e o segundo de com 3 digitos, apos capturar o primeiro grupo ele adiciona um ponto antes do segundo grupo de número
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1/$2') // captura 2 grupos de número o primeiro e o segundo com 3 digitos, separados por /
    .replace(/(\d{4})(\d)/, '$1-$2')
    .replace(/(-\d{2})\d+?$/, '$1'); // captura os dois últimos 2 números, com um - antes dos dois números
};

export const sortByDate = (array) => {
  const sortedArray = array.sort(
    (a, b) => new Date(a.created) - new Date(b.created)
  );

  return sortedArray;
};

/**Converte Data yyyy/mm/dd para dd/mm */
export const dateConvert = (date) => {
  if (!date) {
    return undefined;
  }

  const datePart = date.match(/\d+/g);
  const month = datePart[1];
  const day = datePart[2];
  const years = datePart[0];

  return `${day}/${month}/${years}`;
};

export function simpleDateTimeToText(value) {
  if (!value) return '';

  const dateTimeSplited = value.split('T');

  let date;

  if (dateTimeSplited.length > 1) {
    date = new Date(value);
  } else {
    date = new Date(`${value}T00:00:00`);
  }

  let day = date.getDate();
  let month = date.getMonth() + 1;
  const year = date.getFullYear();

  if (month < 10) {
    month = `0${month}`;
  }

  if (day < 10) {
    day = `0${day}`;
  }

  return `${day}/${month}/${year}`;
}

export const zipCodeConvert = (zipCode) => {
  if (!zipCode) return '';

  if (zipCode.length < 8) return zipCode;

  zipCode = zipCode.toString();
  var re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/; // Pode usar ? no lugar do *

  if (re.test(zipCode)) {
    return zipCode.replace(re, '$1$2-$3');
  }

  return '';
};

export const aliasPaymentType = (name) => {
  switch (name) {
    case 'INVOICE':
      return 'Boleto';
    case 'BANK_TRANSFER':
      return 'Transfêrencia Bancária';
    case 'CREDIT_CARD':
      return 'Cartão de Crédito';
    case 'PIX':
      return 'PIX';
    default:
      return 'Outros';
  }
};

export const aliasPaymentOptionName = (name) => {
  switch (name) {
    case 'Boleto_Faturado':
      return 'Pagamento Faturado';
    case 'Invoice':
      return 'Boleto';
    case 'CC_Generic':
      return 'Cartão de Crédito';
    case 'Pix':
      return 'PIX';
    default:
      return 'Outros';
  }
};

export const valueStatusConvert = (prop) => {
  switch (prop) {
    case 'ANALYSIS':
      return 'Pedido Realizado';
    case 'BILLED':
      return 'Pedido Faturado';
    case 'SENT':
      return 'Pedido Enviado';
    case 'PENDING_PAYMENT':
      return 'Pendente de Pagamento';
    case 'DELIVERED':
      return 'Pedido Entregue';
    case 'FINISHED':
      return 'Pedido Concluído';
    case 'PAID':
      return 'Pagamento Autorizado';
    case 'PENDING':
      return 'Pedido pendente';
    case 'CANCELED':
      return 'Pedido Cancelado';
    case 'RETURNED':
      return 'Pedido Devolvido';
    case 'CAPTURED':
      return 'Pedido Capturado';
    case 'ACCEPTED':
      return 'Pedido Aceito';
    case 'AUTHORIZED':
      return 'Pedido Autorizado';
    default:
      return prop;
  }
};

export const valueStatusClient = (prop) => {
  switch (prop) {
    case 'NOVO':
      return 'Novo';
    case 'PENDENTE':
      return 'Pendente Consulta Externa';
    case 'AAC':
      return 'Pendente Análise de Crédito';
    case 'BLOQUEADO':
      return 'Bloqueado';
    case 'AS':
      return 'Aguardando Segmentação';
    case 'APROVADO':
      return 'Aprovado';
    case 'REPROVADO':
      return 'Reprovado';
    default:
      return prop;
  }
};

export const inversionValueStatusClient = (prop) => {
  switch (prop) {
    case 'Novo':
      return 'NOVO';
    case 'Pendente Consulta Externa':
      return 'PENDENTE';
    case 'Pendente Análise de Crédito':
      return 'AAC';
    case 'Bloqueado':
      return 'BLOQUEADO';
    case 'Aguardando Segmentação':
      return 'AS';
    case 'Aprovado':
      return 'APROVADO';
    case 'Reprovado':
      return 'REPROVADO';
    default:
      return prop;
  }
};
export const getHoursFromBranchOpeningHours = ({
  openingHoursString,
  isOpen,
}) => {
  const time = openingHoursString
    ?.split('as')
  [isOpen ? 0 : 1]?.replace(/\s/g, '');

  return time;
};

export const getHoursFromStoreOpeningHours = ({
  businessHoursString,
  isOpen,
}) => {
  return isOpen
    ? businessHoursString?.split(',')[0].split(' - ')[0]
    : businessHoursString?.split(',')[1].split(' - ')[1];
};

/**Retorna data convertida
 * pega o valor 23/12/2023 *
 * converte para 2023-12-23
 */
export const convertDateFormat = (dateConvert) => {
  var arr1 = dateConvert.split('/');
  var newDate = `${arr1[2]}-${arr1[1]}-${arr1[0]}`;
  return newDate;
};

export const convertHourFormat = (dateConvert) => {
  if (dateConvert !== undefined) {
    let dateOne = dateConvert.split('T');
    let hour = dateOne[1].split('.');
    return hour[0];
  }
};

export const formatPercentage = (number) => {
  const formattedNumber = Number(number).toFixed(1);
  return `${formattedNumber}%`;
};

export const mapCategories = async (data) => {
  const result = [];

  function recursiveMap(categories) {
    for (const category of categories) {
      result.push({
        value: category.uuid,
        label: category.categoryName,
      });

      if (category.childrenCategory.length > 0) {
        recursiveMap(category.childrenCategory);
      }
    }
  }

  recursiveMap(data);

  return result;
};

export const formatarDataLine = (data) => {
  const partes = data.split('/');
  if (partes.length === 3) {
    const dia = partes[0];
    const mes = partes[1];
    const ano = partes[2];
    return `${ano}-${mes}-${dia}`;
  } else {
    // Caso a entrada não esteja no formato esperado, você pode retornar a própria entrada ou outra indicação de erro.
    return 'Formato de data inválido';
  }
};

export const formatarDataParam = (data) => {
  const partes = data.split('-');
  if (partes.length === 3) {
    const ano = partes[0];
    const mes = partes[1];
    const dia = partes[2];
    return `${dia}/${mes}/${ano}`;
  } else {
    return 'Data inválida';
  }
};

export const substituirFiltrosCurrency = (html) => {
  const regex =
    /{{\s*data\.[^\s|]+\s*\|\s*currency\s*\(\s*\d+\s*,\s*["']BRL["']\s*\)\s*}}/g;

  const htmlModificado = html.replace(regex, (match) => {
    // Extrai o nome da variável dinâmica
    const variableName = match.match(
      /{{\s*data\.([^\s|]+)\s*\|\s*currency\s*\(\s*\d+\s*,\s*["']BRL["']\s*\)\s*}}/
    )[1];

    // Crie um valor de substituição genérico com base no nome da variável
    return `{{ "{:,.2f}".format(data.${variableName}) | replace(".",",") }}`;
  });

  return htmlModificado;
};

export const restaurarFiltrosCurrency = (html) => {
  const regex =
    /{{\s*"{:,.2f}".format\(data\.([^\s|]+)\)\s*\|\s*replace\(".",","\) }}(?!")/g;

  const htmlModificado = html.replace(regex, (match, variableName) => {
    return `{{ data.${variableName} | currency(2, "BRL") }}`;
  });

  return htmlModificado;
};

export const validatePermissionsComponent = (permission, user = {}) => {
  if (user !== undefined) {
    let autorithiesGroup = [],
      $ = user?.authorityGroups?.forEach((group) => {
        autorithiesGroup = _.concat(autorithiesGroup, group.authorities);
      });

    const userAuthorities = _.concat(autorithiesGroup, user?.authorities || []);

    // Verifica se existe uma correspondência no array de permissões do usuário
    const hasPermission = userAuthorities.some((obj) => {
      return (
        obj.pattern === permission[0]?.pattern &&
        obj.action === permission[0]?.action
      );
    });

    return hasPermission;
  }
};

export const validateSubMenu = (permissions, user = {}) => {
  if (user !== undefined) {
    let autorithiesGroup = [],
      $ = user?.authorityGroups?.forEach((group) => {
        autorithiesGroup = _.concat(autorithiesGroup, group.authorities);
      });

    const userAuthorities = _.concat(autorithiesGroup, user?.authorities || []);

    // Verifica se pelo menos uma correspondência no array de permissões do usuário
    for (const permission of permissions) {
      if (
        userAuthorities.some(
          (obj) =>
            obj?.pattern === permission?.pattern &&
            obj?.action === permission?.action
        )
      ) {
        return true; // Retorna true assim que encontrar uma correspondência válida
      }
    }
    return false;
  } // Retorna false se nenhuma correspondência válida for encontrada
};

export const validatePermissionsMenu = (permission, user = {}) => {
  if (user !== undefined) {
    let autorithiesGroup = [],
      $ = user?.authorityGroups?.forEach((group) => {
        autorithiesGroup = _.concat(autorithiesGroup, group.authorities);
      });

    const userAuthorities = _.concat(autorithiesGroup, user?.authorities || []);

    // Verifica se existe uma correspondência no array de permissões do usuário
    const hasPermission = userAuthorities.some((obj) => {
      return (
        obj.pattern === permission?.pattern && obj.action === permission?.action
      );
    });

    return hasPermission;
  }
};

export const getChannel = (restrictionArray) => {
  const nameValues = [];

  for (const item of restrictionArray) {
    if (item.channels && item.channels.length > 0) {
      // Verifica se o objeto tem uma propriedade "channels" e se a lista não está vazia
      nameValues.push(item.channels[0].name);
    } else if (item.subChannels && item.subChannels.length > 0) {
      nameValues.push(item.subChannels[0].name);
    }
  }
  return nameValues[0];
};

export const returnDiscount = (item) => {
  if (item.productsAbsoluteDiscount > 0) {
    return `R$ ${item.productsAbsoluteDiscount}`;
  } else {
    return `${item.productsPercentageDiscount * 100} %`;
  }
};

export const convertHomeName = (value) => {
  if (value === ComponentHomeTypesEnum.CATEGORY_CAROUSEL) {
    return 'Categorias Destacadas';
  } else if (value === ComponentHomeTypesEnum.BANNER_CAROUSEL) {
    return 'Imagem';
  } else if (value === ComponentHomeTypesEnum.PRODUCT_CAROUSEL) {
    return 'Carrosel';
  }
};

export const generateTree = (catTree) => {
  return catTree.map((ct) => {
    return {
      key: ct.uuid,
      title: ct.categoryName,
      children: ct.childrenCategory
        ? generateTree(ct.childrenCategory)
        : undefined,
      category: ct,
    };
  });
};

export const removeChildrenCategoryByUUID = (data, uuidToRemove) => {
  const newData = JSON.parse(JSON.stringify(data)); // Cria uma cópia do array para não modificar o original

  const removeChildrenCategoryRecursive = (category) => {
    category.childrenCategory = [];
    for (const childCategory of category.childrenCategory) {
      removeChildrenCategoryRecursive(childCategory);
    }
  };

  const findAndRemoveByUUID = (category) => {
    if (category.uuid === uuidToRemove) {
      category.childrenCategory = [];
    }
    for (const childCategory of category.childrenCategory) {
      findAndRemoveByUUID(childCategory);
    }
  };

  for (const item of newData) {
    findAndRemoveByUUID(item);
  }

  return newData;
};

export const generateTreeUuid = (catTree) => {
  return catTree.map((item) => item.uuid);
};

export const removeSpecialCharacters = (value) => {
  const valueToString = value.toString();
  return valueToString.replace(/[^a-zA-Z0-9 ]/g, '');
};

export const converterStringToNumber = (value) => {
  const currencyValue = value;

  // Remover o símbolo da moeda e substituir a vírgula por ponto
  const formattedValue = currencyValue
    .replace('R$ ', '')
    .replace(/\./g, '')
    .replace(',', '.');

  // Converter para número e multiplicar por 100 para incluir os centavos
  const decimalValue = parseFloat(formattedValue);
  return decimalValue;
};

const Decimal = require('decimal.js');

export const converterFractionHour = (horaExcel) => {
  if (typeof horaExcel !== 'number' || horaExcel < 0 || horaExcel > 1) {
    return horaExcel;
  }

  const valorDecimal = new Decimal(horaExcel);
  const segundosNoDia = valorDecimal.times(24 * 60 * 60);

  let horas = Math.floor(segundosNoDia.toNumber() / 3600);
  let minutos = Math.floor((segundosNoDia.toNumber() % 3600) / 60);
  let segundos = Math.floor(segundosNoDia.toNumber() % 60);

  // Verifica se os segundos são "59" e arredonda para cima
  if (segundos === 59) {
    segundos = 0; // Define os segundos para "00"
    minutos++; // Incrementa os minutos
    if (minutos === 60) {
      minutos = 0; // Se os minutos chegarem a 60, redefine para "00"
      horas++; // Incrementa as horas
    }
  }

  const horaFormatada = `${String(horas).padStart(2, '0')}:${String(
    minutos
  ).padStart(2, '0')}:${String(segundos).padStart(2, '0')}`;

  return horaFormatada;
};

//converte formate date "2024-02-29T10:26:33.926-03:00"
//to 29/02/20024
export const formatData = (data) => {
  if (data !== undefined) {
    // Primeiro, parse a data para um objeto Date
    const dataObj = new Date(data);

    // Use a função format do date-fns para formatar a data e o horário
    const dataFormatada = format(dataObj, 'dd/MM/yyyy HH:mm:ss');

    return dataFormatada;
  }
};

export const valueStatusConvertName = (prop) => {
  switch (prop) {
    case 'Pedido Realizado':
      return 'ANALYSIS';
    case 'Pedido Faturado':
      return 'BILLED';
    case 'Pedido Enviado':
      return 'SENT';
    case 'Pedido Entregue':
      return 'DELIVERED';
    case 'Pedido Concluído':
      return 'FINISHED';
    case 'Pagamento Autorizado':
      return 'PAID';
    case 'Pedido pendente':
      return 'PENDING';
    case 'Pedido Cancelado':
      return 'CANCELED';
    case 'Pedido Devolvido':
      return 'REFUNDED';
    case 'Pedido Recusado':
      return 'REFUSED';
    case 'Pedido Capturado':
      return 'CAPTURED';
    case 'Pedido Aceito':
      return 'ACCEPTED';
    case 'Pedido Autorizado':
      return 'AUTHORIZED';
    default:
      return prop;
  }
};


export const formatDateAndTime = (dateString) => {

  if (dateString) {
    const date = new Date(dateString);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses começam do zero
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  } else {
    return ""
  }

};